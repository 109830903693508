body {
    font-family: 'Inter', sans-serif;
  }
  
.outer-wrap {
    display: table;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
}

.middle-wrap {
    display: table-cell;
    vertical-align: middle;
}

.text-gray {
    color: #b2b2b2;
}

.inner-wrap {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.h-auto {
    height: auto !important;
}

.green {
    color: #00ac1d;
}

.yellow {
    color: #ffc107;
}

i {
    vertical-align: middle;
}

.rot-180 {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
}

.left-0 {
    left: 0 !important;
}

.red {
    color: #ff6f61;
}

.fs-18 {
    font-size: 18px;
}

.fs-12 {
    font-size: 12px;
}


.modal-dialog.mw-none {
    max-width: none;
}

.main {
    background: #ffffff;
    min-height: 100vh;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem
}

textarea {
    border-width: 0 0 1px 0;
    border-color: rgba(50, 50, 49, 0.3);
    border-style: solid;
    width: 100%;
    resize: none;
    color: #323231;
    background: none;
    font-size: 15px;
    line-height: 24px;
    padding: 16px 10px 9px 0;
    outline: none;
}

textarea::placeholder {
    color: rgba(50, 50, 49, 0.3);
}

textarea:required:invalid~.bar:before,
textarea:required:invalid~.bar:after {
    background: #ff6f61 !important;
}

textarea.form-input~.bar:before,
textarea.form-input~.bar:after {
    bottom: 6px !important;
}

.form-input:not(.select) textarea:required:focus~.bar:before,
.form-input:not(.select) textarea:required:focus~.bar:after,
.form-input:not(.select) textarea:required:valid~.bar:before,
.form-input:not(.select) textarea:required:valid~.bar:after {
    width: 50%;
}

.form-input:not(.select)>input:read-only~label,
.form-input:not(.select)>input:disabled~label,
.form-input>input[type="time"]~label {
    top: -2px;
    font-size: 12px;
    color: #0099ff;
}

.form-radio~label {
    width: auto;
}

.form-input>input[type="time"]~.bar:after,
.form-input>input[type="time"]~.bar:before,
.form-input:not(.select)>input:disabled~.bar:before,
.form-input:not(.select)>input:disabled~.bar:after,
.form-input:not(.select)>input:read-only~.bar:before,
.form-input:not(.select)>input:read-only~.bar:after {
    background-color: #39b27b !important;
    width: 50% !important;
}

.form-input>input:read-only,
.form-input>input:disabled {
    color: rgb(50, 50, 50)
}

.form-input.select label,
.form-input.date-picker label {
    left: 0;
}

.dropdown-menu input {
    padding: 4px 8px;
    margin-top: 8px;
}

.dropdown-menu .tick-radio label {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 15px !important;
    left: 0 !important;
    top: 0 !important;
    visibility: hidden;
}

.dropdown-menu.show {
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
}

.white-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    padding: 15px;
    width: 100%;
}

.editor-screen-main {
    /* padding-top: 0 !important; */
    padding: 4px 10px !important;
}

@media (max-width: 768px) {
    .editor-screen-main {
        padding: 0px 0px !important;
    }
}

.white-container.editor-screen {
    margin-top: 0 !important;
    padding: 15px;
    background-color: #F9F9F9;
    box-shadow: none;
    border: 1px solid #E8E9EB;
}

.main .options a {
    cursor: pointer;
}

.main .options a,
.main .options i {
    color: #7e7d7b;
    text-decoration: none;
}

.main .options * {
    min-width: 24px;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.main .search,
.modal .search {
    /*top: 0;
    right: 12px;
    bottom: auto;
    left: 14px;
    position: absolute;*/
    top: 0;
    bottom: auto;
    right: -20px;
    opacity: 0;
    width: 100%;
    position: absolute;
    transition: all ease .2s;
    -webkit-transition: all ease .2s;
}

.main .search.show,
.modal .search.show {
    right: 14px;
    opacity: 1;
}

.main .search .input-group:before,
.modal .search .input-group:before {
    content: "search";
    font-family: 'Material Icons';
    position: absolute;
    right: 5px;
    top: 4px;
    z-index: 9;
    color: rgba(25, 29, 33, 0.7);
}

.main .search .input-group-text,
.modal .search .input-group-text {
    border: none;
    padding-left: 0;
    background: #fbfaf6;
    cursor: pointer;
}

.main .search i,
.modal .search i {
    font-size: 18px;
}

.main .search .form-control,
.modal .search .form-control {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border: none;
    color: rgba(25, 29, 33, 0.6);
    font-size: 14px;
    border-radius: 4px;
}

.main .search:not(.show),
.modal .search:not(.show) {
    visibility: hidden;
    z-index: -1;
}

.main .inner-page-search,
.modal .inner-page-search {
    opacity: 1;
    visibility: visible !important;
    z-index: unset !important;
    position: relative !important;
    width: 100% !important;
    left: 0;
    right: 0;
}

[type=button].btn,
a.btn {
    border-radius: 3px !important;
    white-space: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
    min-height: 44px;
}

.filter-table {
    table-layout: fixed;
}

#campus-matrix {
    table-layout: fixed;
}

.aplan-table tr {
    /*cursor: pointer;*/
    border-bottom: 1px solid #DDDEE1; 
}

.aplan-table thead th {
    border-bottom: 2px solid #dee2e6 !important;
    border-top: none;
    padding: 10px 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.aplan-table.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent !important;
}
.aplan-table.table-striped tbody tr:hover:nth-of-type(odd) {
    background-color: #F9F9F9 !important;
}

.aplan-table tbody tr:hover {
    background-color: #F9F9F9 !important;
}

.aplan-table thead th:hover{
    background-color: var(--Color-State-Hover-Secondary, #F1F1F1) !important;
    color: var(--Color-Glyph-Brand, #19213A) !important;
}

.aplan-table td {
    padding: 12px 5px;
    border: none;
    vertical-align: middle;
    font-size: 16px;
    line-height: 24px;
}

.accordion-buttons {
    height: 100%;
    position: absolute;
    right: 0;
    top: 8px;
    width: auto;
    opacity: 0;
}

.aplan-table .table-buttons {
    padding: 1px;
    width: 100px;
    text-align: right;
    padding-right: 8px;
}

.aplan-table .table-buttons-visible {
    padding: 1px;
    width: 100px;
    text-align: right;
    padding-right: 8px;
}

.table-buttons {
    opacity: 0;
    transition: opacity ease .2s;
    -webkit-transition: opacity ease .2s;
    position: relative;
}

.table-buttons-visible {
    opacity: 1;
    transition: opacity ease .2s;
    -webkit-transition: opacity ease .2s;
    position: relative;
}

.table-buttons-wrapper {
    display: block;
    position: relative;
}

.table-buttons:before {
    content: "more_vert";
    font-family: 'Material Icons';
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 9;
    color: rgba(25, 29, 33, 0.7);
    display: none;
    font-size: 24px;
}

.table-buttons-visible:before {
    content: "more_vert";
    font-family: 'Material Icons';
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 9;
    color: rgba(25, 29, 33, 0.7);
    display: none;
    font-size: 24px;
}

.table-buttons .table-button,
.accordion-buttons .table-button {
    background-color: transparent;
    border-color: transparent;
    width: 36px;
    height: 36px;
    position: relative;
    text-align: center !important;
}

.table-buttons-visible .table-button,
.accordion-buttons .table-button {
    background-color: transparent;
    border-color: transparent;
    width: 36px;
    height: 36px;
    position: relative;
    text-align: center !important;
}

.table-buttons .table-button span,
.accordion-buttons span {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
}

.table-buttons-visible .table-button span,
.accordion-buttons span {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
}

.table-buttons .table-button i,
.accordion-buttons i {
    vertical-align: middle !important;
    font-size: 18px;
    color: grey;
    line-height: 36px;
}

.table-buttons-visible .table-button i,
.accordion-buttons i {
    vertical-align: middle !important;
    font-size: 18px;
    color: grey;
    line-height: 36px;
}

.table-buttons-wrapper>.table-button>span,
.table-buttons-wrapper>.table-button>span>.btn-label {
    display: none;
}

.table-buttons .btn-light:hover,
.accordion-buttons .btn-light:hover {
    background-color: #d6d6d6;
    border-color: transparent !important;
}

.table-buttons-visible .btn-light:hover,
.accordion-buttons .btn-light:hover {
    background-color: #d6d6d6;
    border-color: transparent !important;
}

.table-buttons .btn-light:not(:disabled):not(.disabled):active,
.table-buttons-visible .btn-light:not(:disabled):not(.disabled):active,
.accordion-buttons .btn-light:not(:disabled):not(.disabled).active,
.accordion-buttons .show>.btn-light.dropdown-toggle,
.table-buttons .show>.btn-light.dropdown-toggle {
    background-color: #c5c5c5;
    border-color: transparent !important;
}

.table-buttons-visible .show>.btn-light.dropdown-toggle {
    background-color: #c5c5c5;
    border-color: transparent !important;
}

.aplan-table td.building a i {
    font-size: 21px;
    vertical-align: middle;
}

.aplan-table td.building a {
    font-weight: 500;
    position: relative;
    left: 20px;
    opacity: 0;
    transition: left ease .2s, opacity ease .2s;
    -webkit-transition: left ease .2s, opacity ease .2s;
}

.aplan-table tr:hover td.building a {
    opacity: 1;
    left: 0;
}

.aplan-table tr:hover .table-buttons,
.card-header:hover .accordion-buttons {
    opacity: 1;
}

.page-sorting {
    color: #b0afac;
    font-size: 14px;
}

.page-sorting .pointer {
    cursor: pointer !important;
}

table.sortable>thead th:hover:not([data-defaultsort=disabled]) {
    background-color: transparent;
    color: #a6abaf;
}

.matrix-data:nth-child(1) {
    font-weight: 500;
    border-right: 2px solid #dee2e6 !important;
}

.matrix-data:not(:nth-child(1)) {
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    position: relative;
    padding: 0;
}

.matrix-row:nth-last-child(1)>.matrix-data:not(:first-child) {
    border-bottom: 2px solid #dee2e6 !important;
}

.matrix-row>.matrix-data:nth-last-child(1) {
    border-right: 2px solid #dee2e6 !important;
}

.matrix-data:hover,
.matrix-head:hover,
.matrix-data-input:hover,
.matrix-data-input:focus,
.matrix-data-input:active {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.matrix-head:hover {
    color: initial !important;
}

.matrix-data-input {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 8px;
}

.matrix-data-input:disabled:hover {
    background-color: initial !important;
}

.matrix-data-input:disabled {
    cursor: not-allowed;
}

.autocomplete-items {
    position: absolute;
    border-radius: 4px;
    z-index: 99;
    top: 37px;
    left: 29px;
    right: 0;
    max-height: 200px;
    background-color: #fff;
    overflow-y: auto;
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    text-align: left;
}

.autocomplete-items div:hover {
    background-color: #eeede9;
}

.autocomplete-items div {
    padding: 6px 12px;
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
}

.table-buttons>.menu-toggle,
.table-buttons>label {
    display: none;
}


.input-follower {
    background-color: #fff;
    position: absolute;
    width: auto;
    min-width: 120px;
    max-width: 200px;
    max-height: 200px;
    left: 0;
    border-radius: 4px;
    top: 37px;
    overflow-y: auto;
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    text-align: left;
    z-index: 99;
}

.input-follower>li {
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
    word-break: break-word;
}

.input-follower>li:hover {
    background-color: #eeede9;
}

.input-wrapper {
    position: relative;
    display: initial;
}

.aplan-table-responsive {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: auto;

}

.aplan-table-responsive.layout-fixed {
    table-layout: fixed !important;
}

td[data-toggle="tooltip"] {
    text-align: center;
}

td[data-toggle="tooltip"] .visibleOnSmall {
    display: none;
}

.form-input input~i {
    color: rgba(50, 50, 49, 0.3);
}

.form-input input:focus~i,
.form-input input:valid~i {
    color: #0099ff;

}


.tick-radio.d-inline-block label {
    align-items: center;
    vertical-align: bottom;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.tick-radio.d-inline-block label input {
    margin-right: 0.5rem;
}

#advance-search.collapsing,
#aplan-time-matrix.collapsing,
#editorScreen.collapsing,
#listTable.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

.btn.mw-none {
    min-width: 0;
}

.has-assigned-classroom .delete-this {
    display: none;
}


.card-header p,
.card-block p {
    font-size: 14px;
}

.card-header button.tag-gray {
    background: #e0e0e0;
}

.tag-glass {
    background-color: transparent;
}

.tag-glass:hover {
    background-color: rgba(0, 0, 0, 0.125);
}

.tag-glass:active {
    background-color: #cccbcb;
}


.card-header {
    cursor: pointer;
}

.modal-from-right .modal-title>span,
.modal-from-right .modal-title>i {
    vertical-align: middle;
}

.tooltip {
    font-size: 12px;
    padding-left: 15px;
}

.aplan-table-responsive td,
.aplan-table-responsive th {
    font-size: 12px !important;


}

.aplan-table-responsive.layout-fixed td,
.aplan-table-responsive.layout-fixed th {
    max-width: 100px;
}

.aplan-table-responsive.layout-fixed th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-tooltip-list {
    padding-left: 15px;
}

.aplan-table-matrix-wrapper {
    position: relative;
    overflow: hidden;
}

.aplan-table-matrix-scroller {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    height: 400px;
    position: relative;
    /* left: -20px; */
}

.aplan-table-matrix {
    border-collapse: collapse;
}

.aplan-tm-sticky-col {
    /* position: -webkit-sticky; */
    position: sticky;
    z-index: 99;
    top: auto;
    /* margin-top: 9px; */
    left: 0px;
    /* transform: rotate(-90deg); */
    height: 30px;
}

.aplan-table-matrix thead th>div {
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}

th.aplan-tm-sticky-col:first-child {
    margin-top: 0;
    left: 0;
    transform: none;
    width: 30px;
    /* border-left: 1px solid #dee2e6; */

}

.aplan-tm-sticky-col>div {
    text-align: center;
    background-color: rgb(242, 242, 242) !important;
    /* padding-bottom: 13px; */
    border-top: 0px solid #dee2e6;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.aplan-tm-sticky-col,
.aplan-table-matrix th,
.aplan-tm-sticky-col>div {
    font-weight: 700 !important;
}

.aplan-table-matrix th>div {
    padding: 7px;
    text-align: center;
}


.aplan-table-matrix {
    width: auto;
}

.aplan-table-matrix thead tr th div {
    background-color: rgb(242, 242, 242) !important;
}

.course-table thead tr th div {
    background-color: rgb(242, 242, 242) !important;
    padding-top: 15px;
}


.aplan-table-matrix thead tr>.aplan-tm-sticky-col>div {
    color: rgba(0, 0, 0, 0);
}

.aplan-table-matrix th>div,
.aplan-table-matrix td>div {
    font-size: 12px;
    position: relative;
    cursor: pointer;

}

.aplan-table-matrix td.aplan-tm-sticky-col>div,
.aplan-table-matrix td>div {
    border-bottom: 1px solid #dee2e6 !important;
}

.aplan-table-matrix th>div,
.aplan-table-matrix td>div {
    border-right: 1px solid #dee2e6 !important;
    font-weight: 500 !important;
}

.aplan-table-matrix tbody tr:last-child .aplan-tm-sticky-col>div {
    border-left: 1px solid #dee2e6 !important;
}


.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div,
.aplan-table-matrix th.aplan-tm-sticky-col,
.aplan-table-matrix th.aplan-tm-sticky-col>div,
.aplan-table-matrix th:not(.aplan-tm-sticky-col)>div {
    height: 50px !important;
}

.aplan-table-matrix td>div,
.aplan-table-matrix th:not(.aplan-tm-sticky-col)>div {
    width: 60px !important;
    /* padding-top: 15px; */
}

.course-table td>div,
.course-table th:not(.aplan-tm-sticky-col)>div {
    width: 120px !important;
}

.aplan-table-matrix th:nth-child(1)>div,
.aplan-table-matrix td:nth-child(1)>div {
    height: 50px;
    width: 40px !important;
}


.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div:before {
    content: "add";
    font-family: 'Material Icons';
    z-index: 9;
    color: rgba(25, 29, 33, 0.1);
    font-size: 26px;
    margin: auto;
    width: 50px;
    text-align: center;
    vertical-align: middle;
    display: block;
    height: 100%;
    padding-top: 6%;
    word-wrap: normal;
}

.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div:hover {
    background-color: rgba(0, 0, 0, 0.075);
}


.aplan-table-matrix td>div.on {
    background-color: #39b27b !important;
}

.aplan-table-matrix td>div.admin_force_on {
    background-color: #39b27b !important;
}

.aplan-table-matrix td>div.on.blue {
    background-color: #0099ff !important;
}

.aplan-table-matrix td>div.on.orange {
    background-color: #fd7e14 !important;
}

.aplan-table-matrix td>div.on.red {
    background-color: #ff6f61 !important;
}

.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div.on:before {
    color: #fff;
    content: "done";
}

.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div.admin_force_on:before {
    color: #fff;
    content: "verified_user";
}

.aplan-table-matrix td>div.own_exam.green {
    background-color: #39b27b !important;
}

.aplan-table-matrix td>div.own_exam.blue {
    background-color: #349edb !important;
}

.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div.own_exam:before {
    color: #fff;
    content: "person";
}

.aplan-table-matrix td>div.invigilator.red {
    background-color: #ff6f61 !important;
}

.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div.invigilator:before {
    color: #fff;
    content: "remove_red_eye";
    /* cursor: not-allowed; */
}

.aplan-table-matrix td>div.both.green {
    background-color: #39b27b !important;
}

.aplan-table-matrix td:not(.aplan-tm-sticky-col)>div.both:before {
    color: #fff;
    content: "done";
    /* cursor: not-allowed; */
}

.aplan-table-matrix td div.unavailable:before {
    display: none;
}

.aplan-table-matrix td div.unavailable {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAW+jEh8XwYGhs0wATAHJAkSgHNAAgBWLgNSw1NfCAAAAABJRU5ErkJggg==) repeat;
    cursor: not-allowed;
}

.aplan-table-matrix th,
.aplan-table-matrix td {
    background-color: transparent !important;
    padding: 0;
}


.aplan-table-matrix tr {
    background-color: transparent !important;
}

.aplan-tm-sticky-col div:hover,
.aplan-table-matrix th div:hover {
    background-color: #dcdcdc !important;

}


.aplan-table-matrix thead th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    top: 0;
    z-index: 99;
}

.aplan-table-matrix tbody th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    left: 0;
}


.aplan-table-matrix thead th:first-child {
    /* left: 19px; */
    z-index: 100 !important;
}

header .menu>li .dropdown-menu a {
    color: rgba(0, 0, 0, 0.9);
}

header .menu>li .dropdown-menu {
    border: none;
}

header .menu>li .dropdown-menu i {
    vertical-align: top;
    font-size: 22px;
}

header .menu>li .dropdown-menu .dropdown-item:hover,
header .menu>li .dropdown-menu .dropdown-item:active,
header .menu>li .dropdown-menu .dropdown-item:focus {
    background-color: #f8f9fa !important;
    color: rgba(0, 0, 0, 0.9);
}


.tick-radio .form-radio:checked:before {
    word-wrap: normal;
}

input[type=checkbox].green:checked {
    background-color: #39b27b !important;
    border-color: #39b27b !important;
}

input[type=checkbox].orange:checked {
    background-color: #fd7e14 !important;
    border-color: #fd7e14 !important;
}

input[type=checkbox].red:checked {
    background-color: #ff6f61 !important;
    border-color: #ff6f61 !important;
}

@media (min-width: 1025px) {
    .modal-from-right .modal-dialog {
        position: fixed;
        margin: 0;
        width: 520px;
        height: 100%;
        right: 0;
        top: 0;
        transform: translate(100%, 0);
        transition: transform .3s ease-out;
    }

    .modal-from-right.show .modal-dialog {
        transform: none;
    }
}

.modal-from-right .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #FFFFFF;
}

.modal-from-right .modal-body {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    padding-bottom: calc(1rem + 80px);
    background-color: #FFFFFF;
    position: relative;
}

.modal-from-right .modal-footer {
    background-color: #FFFFFF;
    border-top: 1px solid #E8ECED;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1030;
}

.modal-from-right .modal-header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #E8ECED;
    position: sticky;
    top: 0;
    z-index: 1030;
}

.image-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    padding: 1rem;
    transition: all 150ms;
    border: 2px solid transparent;
    box-sizing: border-box;
}

.image-card.favorited {
    border-color: #39b27b;
}

.image-card__content {
    flex-grow: 1;
    width: 100%;
    position: relative;
}

.image-card__image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 0.25rem;
}

.image-card__controls {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding-left: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.image-card__favorite-btn {
    opacity: 0;
    transition: opacity 150ms;
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.image-card__favorite-btn.favorited {
    opacity: 1;
}

.image-card:hover .image-card__favorite-btn {
    opacity: 0.9;
}

.image-card__action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    opacity: 0;
    transition: opacity 150ms;
}

.image-card:hover .image-card__action-buttons {
    opacity: 0.9;
}

.image-card__action-btn {
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.image-card__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 0.5rem;
}

.image-card__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.image-card__title {
    font-size: 0.875rem;
    padding-right: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-card__timestamp {
    font-size: 0.75rem;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-card__badge {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}

.image-card__delete-btn {
    width: fit-content;
}

.inner-page-nav .nav {
    display: block;
    flex-wrap: unset;
}

.inner-page-nav .nav-tabs a.active {
    color: #323232;
    position: relative;
    background: none;
}

.inner-page-nav .nav-tabs a {
    color: #646462;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: inline-block;
    border: none;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
}


.inner-page-nav .nav-tabs a.active:after {
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    content: "";
    height: 2px;
    background: #191911;
}

.left-bar .nav-tabs a {
    /*text-overflow: ellipsis;*/
    white-space: normal;
    overflow: hidden;
    width: auto;
    line-height: 1.3;

}

.generic-wrapper {
    padding: 15px;
    border-radius: 4px;
    /*border: 1px solid #dee2e6;*/
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    position: relative;
}

@media (max-width: 768px) {
    .generic-wrapper {
        padding: 6px;
    }
}



.small-wrapper {
    height: 475px;
    overflow-x: hidden;
    overflow-y: auto;
}

.large-wrapper {
    min-height: 300px;
    max-height: 775px;
    overflow-x: hidden;
    overflow-y: auto;
}


.inner-cell-wrapper {
    vertical-align: middle;
    width: 100%;
    padding: 5px 8px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dee2e6 !important;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inner-cell-wrapper:hover {
    background-color: #dee2e6 !important;
}

.inner-cell-wrapper.active {
    background-color: #dee2e6;
}

.inner-cell-wrapper span,
.inner-cell-wrapper a {
    display: inline-block;
    vertical-align: text-bottom;
}

.personnal-list-item {
    position: relative;
}


.personnal-list-item-popper {
    position: relative;
    opacity: 0;
    outline: none;
    float: right;

}

.inner-cell-wrapper:hover .personnal-list-item-popper {
    opacity: .2;
}


.personnal-list-item-popper:hover {
    opacity: .5 !important;
}

.inner-cell-wrapper.active .personnal-list-item-popper {
    opacity: .2;
}

.personnal-list-item-popper i {
    font-size: 18px;
    color: #000 !important;
    vertical-align: text-bottom;
}

.popover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    border: none;
}

.popover-inner-html,
.popover-header {
    font-size: 14px;
}

.editor-screen .card {
    border-radius: 4px;
}

.editor-screen .card-header {
    background-color: transparent;
    padding: 0;
}

.editor-screen .card-header:hover {
    background-color: #f1f1f1;
}

.editor-screen .collapse.show~.card-header {
    background-color: #ddd;
}

.editor-screen .card-header:first-child {
    border-radius: 4px 4px 0 0;
}

.editor-screen .card-header button {
    color: rgba(0, 0, 0, .9) !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.editor-screen .card-body {
    position: relative;
}

.editor-screen .card-buttons {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
}


.editor-screen .card-buttons button {
    width: 50%;
    box-shadow: none;
    background-color: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    max-width: 50%;
    min-width: 0;
    white-space: unset;
    padding-bottom: 10px;
    padding-top: 10px;
    height: auto;

}

.editor-screen .card-buttons button:hover {
    background-color: #f1f1f1;
}


.editor-screen .card-buttons button:active {
    background-color: #cccbcb;
}

.editor-screen .card-buttons button:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.125);

}

.editor-screen .card-buttons button:first-child {
    border-radius: 0 0 0 4px !important;
}

.editor-screen .card-buttons button:last-child {
    border-radius: 0 0 4px 0 !important;
}

.editor-screen .card-body h6,
.editor-screen .card-body p {
    font-size: 14px !important;
}


.searchWrapper i,
.searchWrapper a,
.quick-actions i {
    color: #7e7d7b;
    text-decoration: none;
    cursor: pointer;
}

.searchWrapper .search {
    width: auto !important;
    left: 30px;
    right: 30px !important;
}

.searchWrapper .search.show {
    left: 15px;
    background-color: #fff;
}

.searchWrapper a {
    margin-right: 15px;
}

.searchWrapper .input-group-text {
    background-color: transparent !important;
}

.searchWrapper .input-group.autocomplete:before {
    display: none;
}

.searchWrapper .input-group-prepend i {
    font-size: 24px;
}

.form-header {
    padding: 10px 20px 10px 10px;
    background-color: #E2E1DD;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
}

.form-footer {
    padding: 10px;
    background-color: #E2E1DD;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: auto;
    z-index: 999;
}

.form-body {
    background-color: #fbfaf6;
    overflow-y: auto;
    padding-top: 68px !important;
    padding-bottom: 68px !important;
    height: 100%;
}

.logo-container {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
}

.circular {
    border-radius: 555px !important;
    width: 48px !important;
    height: 48px !important;
    padding: 0 !important;
    min-height: 0;
    min-width: 0;
}


.btn-back {
    background-color: transparent;
    color: gray;
    border: none;
    cursor: pointer;
}

.btn-back:hover {
    background-color: lightgray;
}

.hour {
    font-weight: 500;
    text-align: center;
    padding: 5px;
    background-color: transparent;
    border: 2px solid #dee2e6;
    border-radius: 4px;
    cursor: pointer;
    color: gray;
    margin-bottom: 14px;
    font-size: 14px;
}

.hour.on {
    background-color: #39b27b !important;
    color: #fff;
    border-color: #39b27b;

}

.custom-padding {
    padding-right: 7px;
    padding-left: 7px;
}

.custom-padding-row {
    padding-right: 10px;
    padding-left: 10px;
}


.form-body .carousel-control-prev,
.form-body .carousel-control-next {
    top: initial;
    color: gray;
    width: auto;
    position: relative;
    font-size: 16px;
}

.form-body .carousel-item {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

.fixed-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
}

.pick-file-modal {
    max-width: 600px;
}

.file-thumb {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 90px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.file {}

.file-name {
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
}


.file-name:last-child {
    opacity: .5;
    margin-top: 0 !important;
}

.file-name:last-child>span:last-child {
    cursor: pointer;

}

.file-name:last-child>span:last-child:hover {
    text-decoration: underline;
}

.file-name:not(:last-child) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}

.remove-file-button {
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, .8);
    border-radius: 555px;
    position: absolute;
    top: -12px;
    right: -12px;
    color: rgba(255, 255, 255, .8);
    display: table;
    text-align: center;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, .8);
    cursor: pointer;
}

.remove-file-button>i {
    font-size: 15px;
    display: table-cell;
    vertical-align: middle;
}

.file-type-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    background-color: #dddddd;
    display: table;
    text-align: center;
}

.file-type-wrapper>i {
    display: table-cell;
    vertical-align: middle;
    font-size: 36px;
}

.file.excel .file-type-wrapper {
    background-color: #d9f7d6;
}


.file.excel .file-type-wrapper>i {
    color: #74d655;
}

.file.pdf .file-type-wrapper {
    background-color: #f3d8d6
}


.file.pdf .file-type-wrapper>i {
    color: #eb4d5c;
}

.file.doc .file-type-wrapper {
    background-color: #e5f5ff
}


.file.doc .file-type-wrapper>i {
    color: #0099ff;
}

.activity-log {
    padding-left: 32px;
    padding-right: 32px;
}

.date {
    width: 64px;
    height: 64px;
    border-radius: 555px;
    border: 2px solid #dee2e6;
    background-color: #fff;
    display: table;
}

.date-wrapper {
    margin-left: -17px;
}

.date-text {
    vertical-align: middle;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
}

.v-line {
    width: 2px;
    height: 15px;
    background-color: #dee2e6;
    display: table;
    margin: auto;
}

.a-bullet {
    width: 30px;
    height: 30px;
    border-radius: 555px;
    background-color: #00b3ee;
    border: 2px solid #fff;
    position: relative;
    margin-right: 10px;
}

.a-bullet>span {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 555px;
    position: absolute;
    left: 7px;
    top: 7px;
}

.item-text {
    font-size: 14px;
}

.text-pale {
    opacity: .5;
}

.overlapping.active {
    -webkit-box-shadow: inset 0px 0px 0px 2px rgba(57, 178, 123, 1);
    -moz-box-shadow: inset 0px 0px 0px 2px rgba(57, 178, 123, 1);
    box-shadow: inset 0px 0px 0px 2px rgba(57, 178, 123, 1);
    background-color: rgba(0, 0, 0, 0.075);
}

.cover {
    overflow: hidden;
    border-radius: 555px;
    width: 72%;
    height: 82%;
    margin: auto;
    filter: blur(.4px);
    transform: scale(1.2);
    border: 2px solid rgba(0, 0, 0, .12);
}

.overlapping.active .cover:before {
    position: absolute;
    width: 100%;
    font-family: "Material Icons";
    content: 'done';
    height: 100%;
    left: 0;
    top: 0;
    color: #fff;
    background-color: rgba(255, 255, 255, .3);
    border-radius: 555px;
    z-index: 9;
    text-align: center;
    font-size: 180%;
}

.overlapping .cover {
    border-color: transparent;
}

.cover-circle {
    width: 100%;
    height: 100%;
    position: relative;
}

.cover-circle>span {
    width: 100%;
    height: 100%;
    position: absolute;
    /*border-right: 2px solid #fff;
    border-top: 2px solid #fff;*/
    top: 25%;
}

.cover-circle>span:hover {
    filter: brightness(90%);
}

.a-item-inner {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #dee2e6;
}

.actions {
    opacity: 0;
}

.a-item:hover .actions {
    opacity: 1;
}

.sl-item {
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    position: relative;
}

.sl-item .sidebar-controls {
    opacity: 0;
}

.sl-item i {
    cursor: pointer;
    opacity: .5;
}

.sl-item:hover .sidebar-controls {
    opacity: 1;
}

.sl-item i:hover {
    opacity: 1;
}

.sl-item .sidebar-controls {
    right: 30px;
}

.sl-item:hover,
.sl-item.active {
    background-color: rgba(0, 0, 0, 0.075);
    cursor: pointer;

}

.sl-item:hover .sidebar-controls,
.sl-item.active .sidebar-controls {
    background: url(../images/sidebar-controls-bg-cream.png) no-repeat center;
    background-size: 100% 100%;
}

.more-button.dropdown-toggle:After {
    content: none;
}

label>span {
    vertical-align: middle;
    cursor: pointer;
}


.newSearch .search {
    top: 0;
}

/* .left-bar-content .search {
    top: 60px !important;
} */

[role=columnheader] {
    position: sticky;
    top: 0;
}

.table-scroller {
    overflow-x: auto;
}

a.category-tag-square,
a.category-tag-square:hover {
    text-decoration: none !important;
}

.sidebar-controls {
    position: absolute;
    width: auto;
    height: auto;
    background: url(../images/sidebar-controls-bg.png) no-repeat center;
    background-size: 100% 100%;
    padding-left: 20px;
    right: 51px;
}

.overlapping {}

.overlapping>span {
    border-radius: 4px;
}

.overlapping>span {
    width: 50%;
    height: 50%;
    float: left;
}

.overlapping>span.confirmed {
    color: #fff;
    text-align: center;

}

.overlapping>span.confirmed i {
    font-size: 15px;
    vertical-align: middle;
}

.overlapping>span:nth-child(1) {
    background-color: #0099ff;
}

.overlapping>span:nth-child(2) {
    background-color: #39b27b;
}

.overlapping>span:nth-child(3) {
    background-color: #fd7e14;
}

.overlapping:before {
    display: none !important;
}


.card-header>.sidebar-controls {
    right: 15px;
    opacity: 0;
}

.card-header>.sidebar-controls i {
    opacity: .5;
}


.card-header:hover>.sidebar-controls {
    background: url(../images/sidebar-controls-bg-conc.png) no-repeat center;
    background-size: 100% 100%;
    opacity: 1;
}

.card-header:hover>.sidebar-controls i:hover {
    opacity: 1;
}


@media (max-width: 1550px) {
    .dropdown-custom {
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .dropdown-custom>* {
        display: block;
    }

    .dropdown-custom>*>* {
        display: inline-block;
    }
}

@media (max-width: 1440px) {
    .three-buttons {
        display: block !important;
    }

    .three-buttons .btn {
        display: block !important;
        margin-bottom: 7px;
        margin-left: 0 !important;
        width: 100% !important;
    }

    .sidebar-controls>* {
        margin-right: 10px !important;
    }

    .sl-item .sidebar-controls>* {
        margin-right: 1px !important;
    }
}

@media (max-width: 1367px) {
    /* .inner-page-nav .nav-tabs a {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 12px;
    } */

    .tick-radio span {
        font-size: 12px;
    }

    .shortened-list .text-truncate {
        font-size: 12px;
    }
}

@media (max-width: 991px) {

    [type=button].btn {
        font-size: 13px !important;
    }

    .modal-from-right .modal-footer button {
        margin-bottom: 10px;
    }

    td[data-toggle="tooltip"] {
        text-align: left;
    }

    td[data-toggle="tooltip"] i:not(.visible-always) {
        display: none;
    }

    td[data-toggle="tooltip"] .visibleOnSmall {
        display: block;
    }

    .aplan-table-responsive {
        display: table;
        table-layout: fixed;
    }

    .aplan-table-responsive.layout-fixed {
        table-layout: auto !important;
    }

    /*Responsive Table*/
    .aplan-table-responsive thead {
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }


    .aplan-table-responsive tr {
        background-color: transparent !important;
        display: block;
        overflow: hidden;
        position: relative;
        height: 40px;
    }

    .aplan-table-responsive tr:not(:nth-child(1)) {
        margin-top: 12px;
    }

    .aplan-table-responsive tr.open {
        height: auto;
        padding-top: 40px;

    }

    .aplan-table-responsive tbody tr:before {
        position: absolute;
        width: 100%;
        height: 40px;
        left: 0;
        top: 0;
        content: attr(data-title);
        background-color: transparent;
        border-radius: 4px;
        padding-top: 8px;
        padding-left: 11px;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid rgba(0, 0, 0, .1);

    }

    .aplan-table-responsive tbody tr:after {
        position: absolute;
        display: block;
        right: 10px;
        font-family: 'Material Icons';
        content: "add";
        right: 10px;
        top: 8px;
    }

    .aplan-table-responsive tr.open:before {
        border-radius: 4px 4px 0 0;
        border-color: transparent;
        background-color: rgba(0, 0, 0, 0.12);
    }

    .aplan-table-responsive tr.open:after {
        /*content: "remove";*/
    }

    .aplan-table-responsive tr.selected:before {
        background-color: #0099ff !important;
        border-color: #0099ff !important;
        color: #fff;
    }

    .aplan-table-responsive tr.selected:after {
        color: #fff;
    }


    .aplan-table-responsive td {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.05);
        display: none;

    }

    .aplan-table-responsive tr.open td {
        display: block;
    }


    .aplan-table-responsive td:nth-last-child(1) {
        border-radius: 0 0 4px 4px;
        border-bottom: none;
    }


    .aplan-table-responsive td:not(:nth-last-child(1))::before {
        content: attr(data-label);
        float: none;
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        max-width: 50%;
    }

    .aplan-table-responsive td:last-child {
        border-bottom: 0;
    }

    /*Responsive Table*/
    /**/
    .aplan-table .table-buttons {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .table-buttons>.menu-toggle,
    .table-buttons>label {
        display: block;
    }

    .aplan-table td.building a {
        opacity: 1 !important;
        left: 0 !important;
    }

    .table-buttons,
    .accordion-buttons {
        opacity: 1;
    }

    .table-buttons:before {
        display: block;

    }

    .table-buttons-wrapper {
        overflow: hidden;
        float: right;
        background-color: #fff;
        position: absolute;
        width: auto;
        height: auto;
        right: 55px;
        top: 0;
        border-radius: 4px;
        display: none;
        -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
        padding-top: 0;
    }

    .table-buttons>.menu-toggle {
        opacity: 0;
    }

    .table-buttons.open>.table-buttons-wrapper {
        display: block;
        z-index: 99;
    }

    .table-buttons.open:before {
        content: 'clear';
    }

    .table-buttons>.menu-toggle:not(:checked)~label:before {
        content: 'clear';
    }

    .table-buttons-wrapper>.table-button {
        display: block;
        text-align: left;
        width: 100%;
        padding: 12px 19px;
        border-radius: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        font-size: 15px;
    }

    .table-buttons-wrapper .table-button:nth-last-child(1) {
        border-bottom: none;
    }

    .table-buttons-wrapper>.table-button>span,
    .table-buttons-wrapper>.table-button>span>.btn-label,
    .table-buttons-wrapper>.table-button>i {
        display: inline-block;
    }

    .aplan-table {
        table-layout: auto !important;
    }

    .aplan-table td,
    .aplan-table th {
        padding: 12px 16px !important;
    }

    .main .search {
        width: 90%;
    }

    .tooltip {
        display: none;
    }

    .table-buttons .menu-toggle {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .aplan-table-responsive .table-buttons-wrapper {
        display: block;
        position: relative;
        width: 100%;
        left: inherit;
        right: inherit;
        box-shadow: none;
        float: none;
        background-color: rgba(0, 0, 0, 0.12);
        border-radius: 0 0 4px 4px;
    }

    .aplan-table-responsive .table-buttons {
        width: auto;
        padding: 0 !important;

    }

    .aplan-table-responsive .table-buttons:before {
        display: none;
    }

    .aplan-table-responsive .table-buttons-wrapper>.table-button {
        display: inline-block;
        width: auto;
        border: none;
        border-radius: 0 !important;
    }

    .aplan-table-responsive .table-buttons .table-button i {
        vertical-align: text-top;
    }

    .has-assigned-classroom .delete-this {
        display: none !important;
    }

    .accordion-buttons {
        bottom: -5px;
        top: auto;
        right: -12px;
        height: auto;
    }

    .aplan-table-responsive.layout-fixed td,
    .aplan-table-responsive.layout-fixed th {
        max-width: none;
        overflow: unset;
    }
}


@media (max-width: 767px) {

    .left-bar .nav-tabs a {
        margin-right: 3px;
        margin-left: 3px;
        line-height: 2.8;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .small-wrapper {
        min-height: 0;
        max-height: 400px;
        height: auto;
    }

    .container-fluid {
        padding-right: 7px;
        padding-left: 7px;
    }

    .editor-title {
        font-size: 18px;
    }

    .white-container.editor-screen {
        padding: 7px !important;
    }

    .generic-wrapper {
        margin-bottom: 9px;
    }

    .inner-page-nav {
        overflow-x: auto;
    }

    .inner-page-nav .nav {
        overflow-x: auto;
        display: block;
        flex-wrap: unset;
    }

    .inner-page-nav .nav-tabs {
        border-bottom: none;
        display: inline-flex;
        width: max-content;
        margin-bottom: 8px !important;
    }

    .inner-page-nav .nav-tabs a {
        display: block;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
        margin-right: 4px;
        margin-left: 0;
        line-height: 2.8;
    }

    .inner-page-nav .nav-tabs a {
        background-color: rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    .inner-page-nav .nav-tabs a.active {
        background-color: #0099ff !important;
        color: #fff;
    }

    .inner-page-nav .nav-tabs a.active:after {
        display: none;
    }

    header .mobile-menu.show .menu>li .dropdown-menu {
        position: relative !important;
        width: 100%;
        height: auto;
        top: 0 !important;
        left: 0 !important;
        float: none;
        margin: 0;
        min-width: unset;
        transform: none !important;
        box-shadow: none;
        background-color: #e2e1de;
    }
}

@media (max-width: 400px) {
    .modal-dialog {
        width: 100%;
    }

    .user-role-selection>div,
    .user-role-selection {
        display: block !important;
    }

}


.col-xl-3 td[data-toggle="tooltip"] {
    text-align: left;
}

.col-xl-3 td[data-toggle="tooltip"] i:not(.visible-always) {
    display: none;
}

.col-xl-3td[data-toggle="tooltip"] .visibleOnSmall {
    display: block;
}

.col-xl-3 .aplan-table-responsive {
    display: table;
    table-layout: fixed;
}

.col-xl-3 .aplan-table-responsive.layout-fixed {
    table-layout: auto !important;
}

/*Responsive Table*/
.col-xl-3 .aplan-table-responsive thead {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


.col-xl-3 .aplan-table-responsive tr {
    background-color: transparent !important;
    display: block;
    overflow: hidden;
    position: relative;
    height: 40px;
}

.col-xl-3 .aplan-table-responsive tr:not(:nth-child(1)) {
    margin-top: 12px;
}

.col-xl-3 .aplan-table-responsive tr.open {
    height: auto;
    padding-top: 40px;

}

.col-xl-3 .aplan-table-responsive tbody tr:before {
    position: absolute;
    width: 100%;
    height: 40px;
    left: 0;
    top: 0;
    content: attr(data-title);
    background-color: transparent;
    border-radius: 4px;
    padding-top: 8px;
    padding-left: 11px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, .1);

}

.col-xl-3 .aplan-table-responsive tbody tr:after {
    position: absolute;
    display: block;
    right: 10px;
    font-family: 'Material Icons';
    content: "add";
    right: 10px;
    top: 8px;
}

.col-xl-3 .aplan-table-responsive tr.open:before {
    border-radius: 4px 4px 0 0;
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.12);
}

.aplan-table-responsive tr.open:after {
    /*content: "remove";*/
}

.col-xl-3 .aplan-table-responsive tr.selected:before {
    background-color: #0099ff !important;
    border-color: #0099ff !important;
    color: #fff;
}

.col-xl-3 .aplan-table-responsive tr.selected:after {
    color: #fff;
}


.col-xl-3 .aplan-table-responsive td {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.05);
    display: none;

}

.col-xl-3 .aplan-table-responsive tr.open td {
    display: block;
}


.col-xl-3 .aplan-table-responsive td:nth-last-child(1) {
    border-radius: 0 0 4px 4px;
    border-bottom: none;
}


.col-xl-3 .aplan-table-responsive td:not(:nth-last-child(1))::before {
    content: attr(data-label);
    float: none;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    max-width: 50%;
}

.col-xl-3 .aplan-table-responsive td:last-child {
    border-bottom: 0;
}

.col-xl-3 .aplan-table-responsive .table-buttons-wrapper {
    display: block;
    position: relative;
    width: 100%;
    left: inherit;
    right: inherit;
    box-shadow: none;
    float: none;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 0 0 4px 4px;
}

.col-xl-3 .aplan-table-responsive .table-buttons-wrapper>.table-button {
    display: inline-block;
    width: auto;
    border: none;
    border-radius: 0 !important;
}

.col-xl-3 .table-buttons {
    opacity: 1;
    width: 100%;
    padding: 0;
}

.col-xl-3 .table-buttons-wrapper>.table-button {
    padding: 12px 19px;
}

.col-xl-3 td[data-toggle="tooltip"] .visibleOnSmall {
    display: block;
}

.col-xl-3 .aplan-table td:not(.table-buttons),
.aplan-table th {
    padding: 12px 16px;
}

.tooltip {
    pointer-events: none;
}

.sidebar-header {
    cursor: pointer;
    padding: 16px 10px 16px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    color: #0D2145;
}

.main-sidebar {
    background-color: #FFFFFF !important;
}

.btn-primary {
    background-color: #0D2145 !important;
    border-color: #0D2145 !important;
}

.btn-secondary {
    background-color: #E8ECED !important;
    border-color: #E8ECED !important;
}

@media (min-width: 768px) {
    .content-container:not(.announcement) .main-sidebar {
        height: calc(100vh - 64px) !important;
        top: 64px !important;
        min-height: 0;
    }
}

@media (min-width: 768px) {
    .content-container .main-sidebar {
        width: 320px;
        height: calc(100vh - 64px);
    }
}

.scrollbar-hide {
    -ms-overflow-style: none;
    /* explorer ve edge */
    scrollbar-width: none;
    /* firefox */
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
    /* chrome, safari ve opera */
}

/* Header styles */
.aplan-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    overflow: hidden;
    background-color: #19213A;
}

.modal-from-right .modal-header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #E8ECED;
}

.modal-from-right .modal-content {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    position: relative;
    z-index: 1;
}

.modal-from-right .modal-dialog {
    position: fixed;
    margin: 0;
    width: 520px;
    height: 100%;
    right: 0;
    top: 0;
    transform: translate(100%, 0);
    transition: transform .3s ease-out;
}

.modal-from-right.show .modal-dialog {
    transform: none;
}

.modal-from-right .modal-body {
    background-color: #FFFFFF;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    padding-bottom: calc(1rem + 80px); /* Add extra padding at bottom to prevent content being hidden behind footer */
    position: relative;
}

.modal-from-right .modal-footer {
    background-color: #FFFFFF;
    border-top: 1px solid #E8ECED;
    padding: 1rem;
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    width: inherit;
    z-index: 2;
}

.modal-from-right .modal-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #FFFFFF;
}
.modal-from-right .close {
    width: 64px;
    height: 64px;
    background-color: white !important;

    &:hover{
        background-color: var(--Color-State-Hover-Secondary, #cfd3d4) !important;
    }

}
.image-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    padding: 1rem;
    transition: all 150ms;
    border: 2px solid transparent;
    box-sizing: border-box;
}

.image-card.favorited {
    border-color: #39b27b;
}

.image-card__content {
    flex-grow: 1;
    width: 100%;
    position: relative;
}

.image-card__image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 0.25rem;
}

.image-card__controls {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding-left: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.image-card__favorite-btn {
    opacity: 0;
    transition: opacity 150ms;
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.image-card__favorite-btn.favorited {
    opacity: 1;
}

.image-card:hover .image-card__favorite-btn {
    opacity: 0.9;
}

.image-card__action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    opacity: 0;
    transition: opacity 150ms;
}

.image-card:hover .image-card__action-buttons {
    opacity: 0.9;
}

.image-card__action-btn {
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.image-card__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 0.5rem;
}

.image-card__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.image-card__title {
    font-size: 0.875rem;
    padding-right: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-card__timestamp {
    font-size: 0.75rem;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-card__badge {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}

.image-card__delete-btn {
    width: fit-content;
}

button i.material-icons {
    color: inherit;
    vertical-align: middle;
}

button.primary i.material-icons {
    color: inherit;
}

button.ghost i.material-icons {
    color: inherit;
}

.uppy-Dashboard-inner{
    background-color: #F9F9F9 !important;
}